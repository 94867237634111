.card {
	$block: &;
	display: block;
	text-align: left;
	width: 100%;

	&__image-wrap {
		position: relative;
		display: block;
		border-radius: theme('borderRadius.lg');

		*,
		&:before,
		&:after {
			border-radius: theme('borderRadius.lg');
			overflow: hidden;
		}

		&::before {
			content: '';
			display: block;
			padding-bottom: calc(100% / (var(--aspect-x) / var(--aspect-y)));
		}

		&::after {
			transition: background-color 0.3s ease;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	&__meta {
		color: theme('colors.gray.400');
		font-size: theme('fontSize.sm');
		margin-top: theme('spacing.4');
	}

	&__title {
		margin-top: theme('spacing.2');
		margin-bottom: theme('spacing.2');
		font-size: theme('fontSize.xl');
	}

	&__info {
		margin-top: theme('spacing.2');
	}

	&__slot-bottom {
		margin-top: theme('spacing.2');
	}

	&:is(a) {
		text-decoration: none;
		&:hover,
		&:focus {
			#{$block} {
				&__image-wrap {
					&::after {
						background-color: rgba(0, 0, 0, 0.2);
					}
				}
			}
		}
	}

	&--orientation {
		&-horizontal {
			#{$block} {
				&__meta {
					margin-top: theme('spacing.4');
					@media (min-width: theme('screens.md')) {
						margin-top: 0;
					}
				}
			}
			@media (min-width: theme('screens.md')) {
				display: flex;
				align-items: flex-start;

				#{$block} {
					&__image-wrap {
						margin-right: theme('spacing.5');
						flex: 1;
					}
					&__content {
						align-self: center;
						flex: 2;
					}
				}
			}
		}

		&-vertical {
			#{$block} {
				&__meta {
					margin-top: theme('spacing.4');
				}
			}
		}
	}

	&--aspect-ratio {
		&-9x5 {
			--aspect-x: 9;
			--aspect-y: 5;
		}
		&-7x9 {
			--aspect-x: 7;
			--aspect-y: 9;
		}
	}
	&--orientation-horizontal#{$block}--aspect-ratio-9x5 {
		#{$block} {
			&__image-wrap {
				flex: 1;
			}
			&__content {
				align-self: flex-start;
				flex: 1;
			}
		}
	}
}
