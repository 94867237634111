.toggler {
	$block: &;
	display: contents;

	&__element {
		display: table;
		cursor: pointer;

		&:hover {
			.toggler__icon {
				border-color: $primary700;
			}
		}
	}

	input {
		position: absolute;
		left: -999vw;

		&:disabled {
			& + #{$block}__element {
				pointer-events: none;
				opacity: 0.3;
			}
		}
	}

	&--display {
		&-inline #{$block}__element {
			display: inline-flex;
		}
	}

	&--variant {
		&-checkbox {
			#{$block} {
				&__element {
					display: flex;
					align-items: center;
				}
				&__icon {
					margin-right: theme('spacing.3');
					display: flex;
					justify-content: center;
					align-items: center;
					border: 1px solid theme('colors.gray.300');
					width: 30px;
					height: 30px;
					color: transparent;
					border-radius: theme('borderRadius.DEFAULT');
				}
			}
			input:checked + * {
				#{$block}__icon {
					background-color: theme('colors.primary.500');
					border-color: theme('colors.primary.500');
					color: white;
				}
			}
		}
		&-button {
			#{$block} {
				&__element {
					transition: padding 0.1s ease-in-out 0.1s, background 0.2s ease-in-out;
					cursor: pointer;
					position: relative;
					white-space: nowrap;
					background-color: theme('colors.gray.100');
					padding: theme('spacing.3') theme('spacing.8');
					border-radius: theme('borderRadius.full');
					font-size: theme('fontSize.base');
					font-weight: bold;
					color: theme('colors.gray.500');
					span {
						line-height: 26px;
					}
				}
				&__icon {
					transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
					opacity: 0;
					position: absolute;
					left: theme('spacing.8');
					top: 50%;
					transform: translateY(-50%) scale(0);
				}
			}

			&:hover {
				#{$block}__element {
					background-color: theme('colors.gray.200');
				}
				input:checked + #{$block}__element {
					background-color: theme('colors.primary.700');
				}
			}

			input:focus-visible + #{$block}__element {
				outline: 2px theme('colors.primary.500') solid;
				outline-offset: 2px;
			}

			input:checked + * {
				transition: padding 0.1s ease-in-out;
				padding-left: theme('spacing.15');
				background-color: theme('colors.primary.500');
				color: white;

				#{$block} {
					&__icon {
						transition: opacity 0.1s ease-in-out 0.1s,
							transform 0.1s ease-in-out 0.1s;
						opacity: 1;
						transform: translateY(-50%) scale(1);
					}
				}
			}
		}
		&-toggler {
			#{$block} {
				&__element {
					transition: background 0.2s ease;
					width: 40px;
					height: 24px;
					border-radius: 9999px;
					background-color: theme('colors.gray.500');
				}
				&__icon {
					transition: left 0.2s ease;
					width: 16px;
					height: 16px;
					background: white;
					display: block;
					border-radius: 9999px;
					position: relative;
					top: 4px;
					left: 4px;
					svg {
						display: none;
					}
				}
			}

			input:checked + {
				#{$block} {
					&__element {
						background-color: theme('colors.primary.500');
						#{$block}__icon {
							left: calc(100% - 20px);
						}
					}
				}
			}
		}
	}
}
