.isActive {
	.activeIndicator {
		opacity: 1 !important;
	}

	[class~='isActive:text-primary-500'] {
		color: theme('colors.primary.500') !important;
	}

	[class~='isActive:bg-primary-500'] {
		background-color: theme('colors.primary.500') !important;
	}
}
