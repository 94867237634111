.#{$rt-namespace}__toast {
	position: relative;
	overflow: hidden;
	direction: ltr;
	&--rtl {
		direction: rtl;
	}
	&--dark {
		background: $rt-color-dark;
		color: $rt-text-color-dark;
	}
	&--default {
		background: $rt-color-default;
		color: $rt-text-color-default;
	}
	&--info {
		background: $rt-color-info;
	}
	&--success {
		background: $rt-color-success;
	}
	&--warning {
		background: $rt-color-warning;
	}
	&--error {
		background: $rt-color-error;
	}
	&-body {
		margin: auto 0;
		flex: 1 1 auto;
	}
	&-icon {
		height: 40px;
		width: 40px;
		flex-shrink: 0;
	}
}

@media #{$rt-mobile} {
	.#{$rt-namespace}__toast {
		margin-bottom: 0;
	}
}
