body .fslightbox {
	&-container {
		background: #ffffffee;
		padding: 0 10px;
	}

	&-toolbar {
		background: transparent;
		right: 0;

		@media (min-width: 768px) {
			padding-right: 12px;
			padding-top: 12px;
		}
	}

	&-slide-btn {
		&-next-container {
			padding-right: 12px;
		}
	}

	@media (max-width: 767px) {
		&-toolbar-button {
			&:nth-of-type(1) {
				display: none;
			}
		}
	}

	&-slide-btn,
	&-toolbar-button {
		border-radius: 9999px;
		background: transparent;
		width: 50px;
		height: 50px;
		margin: 12px;
	}

	&-toolbar-button:hover,
	&-slide-btn-container:hover > * {
		background: #f5f5f5;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);

		svg > * {
			transform: scale(1.1);
			transform-origin: center;
			fill: $primary500 !important;
		}
	}

	&-slide-number-container {
		color: black;
		padding: 20px;
		position: relative;
		top: 12px;
		left: 12px;

		@media (min-width: 768px) {
			top: 25px;
			left: 20px;
		}
	}

	&-slash {
		background-color: black;
	}
}
