.#{$rt-namespace}__toast-container {
	z-index: $rt-z-index;
	width: $rt-toast-width;
	position: fixed;
	top: 0;
	left: 0;
	color: #fff;
	&--top-center {
		top: 0;
	}
}

@media #{$rt-mobile} {
	.#{$rt-namespace}__toast-container {
		width: 100vw;
		&--top-left,
		&--top-center,
		&--top-right {
			top: 0;
			transform: translateX(0);
		}
		&--bottom-left,
		&--bottom-center,
		&--bottom-right {
			bottom: 0;
			transform: translateX(0);
		}
		&--rtl {
			right: 0;
			left: initial;
		}
	}
}
