@import '../assets/fonts/inter/stylesheet';
@import '../assets/fonts/nunito-sans/stylesheet';

* {
	font-family: 'Nunito Sans';
	line-height: 1.33;
}

html {
	font-size: $baseSize;
	font-family: sans-serif;
}

body {
	font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Inter';
	line-height: 1.1;
	font-weight: bold;
}

.prose {
	h2:first-child {
		margin-top: 0;
	}
}
