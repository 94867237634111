.input-range {
	--controlWidth: 38px;
	min-width: 280px;
	margin: 55px calc(var(--controlWidth) / 2) 35px;
	cursor: pointer;

	@media (min-width: 640px) {
		min-width: 340px;
	}

	&__track {
		&--active {
			background: $primary500;
			height: 4px;
			top: 50%;
			transform: translateY(-50%);
			position: absolute;
		}

		&--background {
			background: linear-gradient(white 47%, #bcbcbc 50%, white 53%);
			position: relative;
			height: 35px;
			transform: translateY(-50%);
		}
	}

	&__slider-container {
		top: 50%;
		transform: translateX(-50%);
	}

	&__label {
		display: none;
	}

	&__slider {
		width: var(--controlWidth);
		height: 35px;
		border: 2px solid $primary500;
		border-radius: 11px;
		transform: translateY(-50%);
		background: white;
		cursor: grab;

		&:active {
			cursor: grabbing;
		}

		&::before {
			content: '|||';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(calc(-50% + 1px), -50%);
			color: $gray300;
			font-weight: 300;
			letter-spacing: 2px;
		}
	}
}
