// @Luka isprovat Tailwind aspect ratio plugin
// vidit jel mogu ovo izbacit sad

.aspect-ratio {
	position: relative;

	&::before {
		content: '';
		padding-top: calc(8 / 13 * 100%);
		display: block;
	}

	> * {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&--squere {
		&::before {
			padding-top: 100%;
		}
	}

	&--wide {
		&::before {
			padding-top: calc(1 / 2.5 * 100%);
		}
	}
}

.leading-inherit {
	line-height: inherit;
}

.header-bg-shade {
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.5) 0%,
		rgba(0, 0, 0, 0) 100%
	);
}

@media (min-width: 768px) {
	.md\:modal-shadow {
		position: relative;

		&::after,
		&::before {
			content: '';
			transition: height 0.1s ease, opacity 0.1s ease;
			z-index: 40;
			position: absolute;
			display: block;
			width: 100%;
			background: transparent;
			pointer-events: none;
			height: 0;
			opacity: 0;
		}

		&::before {
			top: 0;
			background: linear-gradient(to bottom, #0001, #0000);
		}

		&::after {
			bottom: 0;
			background: linear-gradient(to top, #0001, #0000);
		}

		&-t::before,
		&-b::after,
		&-y::before,
		&-y::after {
			height: 48px;
			opacity: 1;
		}
	}
}

.pin-c {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.pin-r {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translate(0%, -50%);
}

.pin-l {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(0%, -50%);
}

@media (min-width: 1200px) {
	.xl\:pin-r {
		position: absolute;
		top: 50%;
		right: 0;
		--tw-translate-y: -50%;
	}
}

@media (min-width: 1440px) {
	.\32xl\:pin-r {
		position: absolute;
		top: 50%;
		right: 0;
		--tw-translate-y: -50%;
	}
}

.pin-t-full {
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translate(-50%, 0%);
}

.md\:pin-t-full {
	@media (min-width: 768px) {
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translate(-50%, 0%);
	}
}

.rounded-none-important {
	border-radius: 0 !important;
}

.text-current {
	color: currentColor;
}

.columns-2 {
	column-count: 2;
	display: flex;
	flex-wrap: wrap;
	&:nth-child(1) {
		padding-right: 30px;
	}
	> * {
		flex: 1;
		max-width: 50%;
		min-width: 50%;
	}
}

@media (min-width: 768px) {
	.md\:columns-2 {
		display: flex;
		flex-wrap: wrap;
		> * {
			&:nth-child(1) {
				padding-right: 30px;
			}
			flex: 1;
			max-width: 50%;
			min-width: 50%;
		}
	}
}

.split-with-dots:not(:last-child):after {
	content: '\2022';
	margin-left: 0.25rem;
	font-size: 6px;
	transform: translateY(-0.5em);
	display: inline-block;
}

.inherit-all {
	color: inherit;
	transition: inherit;
	font-size: inherit;
	display: inherit;
	font-weight: inherit;
	align-items: inherit;
	justify-content: inherit;
	text-align: inherit;
}

svg.with-circle {
	overflow: visible;
	background: theme('colors.gray.50');
	border-radius: 50%;
	transform: scale(1) translate(-3px, 3px);

	> * {
		transform: scale(0.8) translate(3px, -3px);
		transform-origin: center;
	}

	&--no-offset {
		transform: scale(1);

		> * {
			// transform: scale(0.8);
			transform: scale(0.6);
		}
	}
}

.darken-bg {
	background-color: #666;
	background-blend-mode: overlay;
}

.darken {
	filter: brightness(0.9);
}

.darken-strong {
	filter: brightness(0.7);
}

.hover\:darken:hover {
	filter: brightness(0.9);
}

.group:hover .group-hover\:darken {
	filter: brightness(0.9);
}
.group:hover .group-hover\:darken-strong {
	filter: brightness(0.7);
}

/* clears the 'X' from Internet Explorer */
input.hide-clear[type='search']::-ms-clear,
input.hide-clear[type='search']::-ms-reveal {
	display: none;
	width: 0;
	height: 0;
}

/* clears the 'X' from Chrome */
input.hide-clear[type='search']::-webkit-search-decoration,
input.hide-clear[type='search']::-webkit-search-cancel-button,
input.hide-clear[type='search']::-webkit-search-results-button,
input.hide-clear[type='search']::-webkit-search-results-decoration {
	display: none;
}

.custom-scroll-styles {
	scrollbar-color: theme('colors.gray.600') transparent;
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: 16px;
	}

	&::-webkit-scrollbar-track {
		margin: 6px 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: theme('colors.gray.600');
		border-radius: 20px;
		border: 6px solid white;
	}
}

// todo: remove after mergeing updated tailwind
.group:hover .group-hover\:scale-110 {
	transform: scale(1.1);
}
