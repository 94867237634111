.#{$rt-namespace}__close-button {
	color: #fff;
	background: transparent;
	outline: none;
	border: none;
	padding: 0;
	cursor: pointer;
	transition: 0.3s ease;
	align-self: center;
	position: relative;
	border-radius: 50%;
	text-align: center;
	height: 30px;
	width: 30px;
	outline: none !important;
	&:hover {
		background: rgb(255 255 255 / 21%);
	}

	&--default {
		color: #000;
	}

	& > svg {
		fill: currentColor;
		height: 16px;
		width: 14px;
		margin: auto;
	}

	&:hover,
	&:focus {
		opacity: 1;
	}
}
