// // GRID SYSTEM

$site-width: $siteWidth;
$number-of-cols: 12;

$gutter: 20px;
$outer-gutter: 40px;

$gutters: #{($number-of-cols - 1) * $gutter};

$col: calc(
	(#{$site-width} - #{$gutters} - 2 * #{$outer-gutter}) / #{$number-of-cols}
);

// - Responsive

$r-col: calc((100vw - #{$gutters} - 2 * #{$outer-gutter}) / #{$number-of-cols});

// - Mobile

$m-number-of-cols: 4;

$m-gutter: 20px;
$m-outer-gutter: 20px;

$m-gutters: #{($m-number-of-cols - 1) * $m-gutter};

$m-col: calc(
	(100vw - #{$m-gutters} - 2 * #{$m-outer-gutter}) / #{$m-number-of-cols}
);

:root {
	--siteWidth: #{$site-width};
	--number-of-cols: #{$number-of-cols};

	--gutter: #{$gutter};
	--gutters: #{$gutters};

	--outer-gutter: #{$outer-gutter};

	--col: #{$col};

	--r-col: #{$r-col};

	--m-gutter: #{$m-gutter};
	--m-outer-gutter: #{$m-outer-gutter};
	--m-number-of-cols: #{$m-number-of-cols};
	--m-gutters: #{$m-gutters};
	--m-col: #{$m-col};
}
