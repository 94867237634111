.button {
	$root: &;
	transition:
		background-color 0.2s ease,
		color 0.2s ease,
		border-color 0.2s ease;
	font-weight: bold;
	font-size: var(--font-size);
	padding: calc(var(--py) + 2px) var(--px) var(--py);
	height: var(--height);
	border-radius: var(--rounded);
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	white-space: nowrap;

	> span {
		margin: 0 auto;
	}

	&:hover {
		--color: var(--color-accent) !important;
	}

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	svg {
		width: 12px;
		height: 12px;
		transform: scale(2);

		* {
			fill: currentColor;
		}
		&:first-child {
			margin-right: calc(0.5em + 6px);
			margin-left: 6px;
		}
		&:last-child {
			margin-left: 0.5em;
		}
	}

	&.w-full svg:last-child {
		margin-left: auto;
	}

	&--variant {
		&-fill {
			color: var(--color-contrast);
			background-color: var(--color);
			border: 2px solid var(--color);
		}
		&-outline {
			color: var(--color);
			border: 2px solid var(--color);
		}
		&-link {
			color: var(--color);
			text-decoration: underline;
			border-radius: 2px !important;
			padding: 0 !important;
		}
	}

	&--color {
		&-primary {
			--color-contrast: theme('colors.white');
			--color: theme('colors.primary.500');
			--color-accent: theme('colors.primary.700');
		}
		&-brand {
			--color-contrast: theme('colors.black');
			--color: theme('colors.brand');
			--color-accent: theme('colors.brand');
		}
		&-dark {
			--color-contrast: theme('colors.white');
			--color: theme('colors.gray.600');
			--color-accent: theme('colors.gray.500');
		}
		&-light {
			--color: theme('colors.gray.300');
			--color-accent: theme('colors.gray.200');

			&#{$root}--variant-fill {
				--color-contrast: theme('colors.black');
				--color: theme('colors.gray.100');
				--color-accent: theme('colors.gray.200');
			}
		}
		&-transparent {
			--color: theme('colors.gray.500');
			--color-accent: theme(colors.black);

			&#{$root}--variant-outline {
				border-color: transparent;
				&:hover {
					border-color: var(--color);
				}
			}
			&#{$root}--variant-fill {
				--color-contrast: theme('colors.gray.500');
				--color: transparent;
				--color-accent: theme('colors.gray.100');
			}
		}
	}

	&--size {
		&-sm {
			--font-size: theme('fontSize.base');
			--py: theme('spacing.2');
			--px: theme('spacing.4');
			--height: 42px;
		}
		&-md {
			--font-size: theme('fontSize.md');
			--py: theme('spacing.3');
			--px: theme('spacing.7');
			--height: 50px;
		}
	}

	&--rounded {
		&-lg {
			--rounded: theme('borderRadius.lg');
		}
		&-full {
			--rounded: theme('borderRadius.full');
		}
	}

	&-group {
		&--horizontal {
			display: flex;
			position: relative;

			> .button {
				&:not(:last-child) {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border-right: 0;
					position: relative;
					&::after {
						content: '';
						width: 16px;
						top: 50%;
						border-top: 1px solid;
						right: -8px;
						position: absolute;
					}
				}
				&:last-child {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					border-left: 0;
				}
			}
		}
	}
}
