@import '../../../node_modules/react-dates/lib/css/_datepicker.css';
.DateRangePickerInput {
	padding: 6px 28px;
	display: flex;
	border: 2px solid $gray500;
	align-items: center;
	border-radius: 9999px;
}

.DayPicker_weekHeader {
	&_li {
		width: 48px !important;
		line-height: 30px;
		font-size: bold;

		small {
			font-size: 14px;
			color: theme('colors.gray.300');
		}
	}
	&__verticalScrollable {
		.DayPicker_weekHeader_li {
			width: 44px !important;
		}
		border: none;
		background-color: theme('colors.gray.100');
		padding: 0;
	}
}

.DayPicker_transitionContainer__verticalScrollable {
	padding-top: 50px;
}

.DayPickerNavigation_button__vertical {
	margin: 30px auto;
}

.DayPickerNavigation .DateInput {
	width: auto;
	display: flex;
	align-items: center;

	&:nth-child(2) {
		&::after {
			content: '';
			display: block;
			height: 12px;
			margin: 0 10px;
			width: 2px;
			background: $primary500;
		}
	}

	&_input {
		color: $primary500;
		width: 9ch;
		padding: 0;
		font-size: $textBase;
		font-family: 'Inter', sans-serif;
		border: none;
		font-weight: 700;

		&__focused {
			color: $primary700;
			text-decoration: underline;
		}
	}
}

.DateRangePickerInput {
	&_arrow {
		display: none;
	}
}

.CalendarDay {
	outline: none !important;
	border: none !important;
	color: $gray500;
	position: relative;

	&:hover {
		background: $gray100;
	}

	&__selected {
		border-radius: 100%;
		background: $primary500;
		color: white;
		z-index: 1;

		+ *::before {
			content: '';
			display: block;
			position: absolute;
			inset: 0;
			background: $gray100;
			pointer-events: none;
		}

		+ .CalendarDay__selected_span {
			&::before {
				right: 100%;
				left: -50%;
			}

			&:hover:before {
				background: $gray200;
			}
		}

		.CalendarDay__selected
			.CalendarDay__selected_span:hover
			+ .CalendarDay__selected
			+ *::before {
			background: $gray200;
		}

		+ *:not(.CalendarDay__selected_span)::before {
			right: 150%;
			left: -100%;
		}

		&:hover {
			background: $primary700;
		}

		&_span {
			background: $gray100;

			&:hover {
				background: $gray200;
				color: $gray500;
			}
		}
	}

	&__hovered_span {
		background: $gray100;
		color: $primary500;
	}
}

.DayPicker {
	box-shadow: none;
	width: calc(100% - 20px);

	@media (max-width: 819px) {
		width: 100%;
		overflow: hidden;
	}
}

.DayPickerNavigation__verticalScrollable_prevNav {
	display: none !important;
}

@media (max-width: 819px) {
	.CalendarMonth {
		padding: 0 !important;
	}

	.CalendarMonthGrid__vertical {
		width: auto !important;

		> * {
			width: 100%;
		}
	}
}

.CalendarMonth {
	background: transparent;
	&Grid {
		background: transparent;
		z-index: 2;
	}
	&_caption {
		padding: 23px 0 60px;
		&__verticalScrollable {
			padding: 30px 0 10px;
		}
	}

	&_table {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: calc(7 * 54px);
		font-size: theme('fontSize.md');
		color: theme('colors.gray.600');
	}
}

.shadow .DayPicker {
	box-shadow: none !important;
}

.DayPickerKeyboardShortcuts_buttonReset {
	display: none;
}

.DayPickerNavigation {
	display: flex;

	&_button__disabled {
		cursor: default !important;
		border: none !important;
		pointer-events: none !important;
		opacity: 0.5;
	}

	&__horizontal {
		justify-content: space-between;
		position: absolute;
		inset: 10px 30px auto;

		.DayPickerNavigation_button:first-child button {
			transform: rotate(90deg);
		}

		.DayPickerNavigation_button:last-child button {
			transform: rotate(-90deg);
		}
	}
}

.DateRangePickerInput_calendarIcon {
	padding: 0;
	color: $primary500;
	display: flex;
}

.checkout-only,
.violets-min-nights {
	color: $gray300;
}

.blocked.blocked-calendar {
	color: $gray100;
	text-decoration: line-through;
	pointer-events: none !important;
	cursor: pointer;
}

.blocked.blocked-out-of-range {
	color: $gray100;
	text-decoration: line-through;
	pointer-events: none !important;
	cursor: pointer;
}

.blocked-minimum-nights {
	color: $gray400;
	cursor: default;
	pointer-events: all !important;
	cursor: pointer;

	&:hover {
		border-color: $gray400 !important;
	}
}

.DayPicker__verticalScrollable {
	height: calc(100vh - 310px);
	@media (min-width: 768px) {
		max-height: calc(min(850px, 90vh) - 235px);
	}
}
