@media (min-width: 820px) {
	.loadMore {
		display: none !important;
	}
}

@media (max-width: 819px) {
	.arrow {
		display: none !important;
	}
}
