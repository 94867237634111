$rt-namespace: 'Toastify';
$rt-toast-width: 100vw !default;
$rt-toast-background: #fff !default;
$rt-toast-max-height: 800px !default;

$rt-color-default: #fff !default;
$rt-color-dark: #302e2c !default;
$rt-color-info: #3498db !default;
$rt-color-success: #509e2f !default;
$rt-color-warning: #ffd700 !default;
$rt-color-error: #eb445a !default;

$rt-text-color-default: #aaa !default;
$rt-text-color-dark: #fff !default;

$rt-color-progress-default: linear-gradient(
	to right,
	#4cd964,
	#5ac8fa,
	#007aff,
	#34aadc,
	#5856d6,
	#ff2d55
) !default;
$rt-color-progress-dark: #bb86fc !default;
$rt-mobile: 'only screen and (max-width : 480px)' !default;
$rt-font-family: sans-serif !default;
$rt-z-index: 9999 !default;
