body {
	margin: 0;
	min-height: 100vh;
	padding: 0;
	position: relative;
	overflow-x: hidden;
	background: white;
}

img {
	max-width: 100%;
	height: auto;
}

nav a {
	text-decoration: none;
}

a,
button {
	cursor: pointer;
	background-color: transparent;
	border-width: 0;
	font-size: 1rem;
	color: inherit;
}

*:focus-visible {
	outline: 2px theme('colors.primary.500') solid;
	outline-offset: 2px;
}

svg:not(.svg-no-prefill),
svg:not(.svg-no-prefill) * {
	fill: currentColor;
}

.container {
	max-width: $siteWidth;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: auto;
	margin-right: auto;

	@media (min-width: 768px) {
		padding-left: 40px;
		padding-right: 40px;
	}
}

@media (min-width: 550px) {
	.sm\:container {
		max-width: $siteWidth;
		padding-left: 20px;
		padding-right: 20px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 768px) {
	.md\:container {
		max-width: $siteWidth;
		padding-left: 20px;
		padding-right: 20px;
		margin-left: auto;
		margin-right: auto;
	}
}

hr {
	border-style: solid;
	border-top-width: 1px;
}

.styled-link {
	@apply inline-flex underline;
	color: var(--color);

	&--gray {
		--color: theme('colors.gray.300');
		--hover-color: theme('colors.gray.400');
	}

	&:hover,
	&:focus {
		color: var(--hover-color);
		@apply no-underline;
	}
}
