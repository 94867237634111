.bg-pulse > span:nth-child(1) {
	animation: bgPulse 2s infinite;

	img {
		animation: fadeIn 0.5s 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes bgPulse {
	0% {
		background-color: $gray100;
	}

	50% {
		background-color: $gray200;
	}

	100% {
		background-color: $gray100;
	}
}
