.react-select__value-container {
	padding: 0.75rem 1.25rem !important;
}

.react-select__control {
	border-radius: 6px !important;
}

.react-select__control:hover {
	border-radius: 6px !important;
}

.react-select__control--is-focused {
	border-color: $primary500;
}

.react-select__placeholder {
	color: #959595 !important;
}

.react-select__indicator {
	svg {
		color: black;
	}
}
