$siteWidth: 1220px;
$baseSize: 16px;

$brand: #f9e700;
$primary500: #254da1;
$primary700: #0c3180;
$black: #000;
$red: #da291c;
$white: #fff;
$gray50: #f5f5f5;
$gray100: #ededed;
$gray200: #cfcfcf;
$gray300: #bcbcbc;
$gray400: #959595;
$gray500: #3f3f3f;

$text4xl: 70px;
$text3xl: 42px;
$text2xl: 35px;
$textxl: 28px;
$textlg: 20px;
$textMd: 18px;
$textBase: 16px;
$textSm: 14px;
$textXs: 12px;
