.input {
	$block: &;
	position: relative;
	&__element {
		position: relative;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type='number'] {
		-moz-appearance: textfield;
	}
	input {
		width: 100%;
		&:focus-visible {
			outline: 2px theme('colors.primary.500') solid;
			outline-offset: 2px;
			position: relative;
			z-index: 1;
		}

		&:read-only {
			&::selection {
				background: transparent;
			}
		}
	}

	&--variant {
		&-default {
			input {
				padding: 0 theme('spacing.5');
				line-height: theme('spacing.11');
				font-size: theme('fontSize.md');
				border-radius: theme('borderRadius.DEFAULT');
				border: 1px solid theme('colors.gray.300');
			}
			#{$block} {
				&__label {
					display: block;
					font-size: theme('fontSize.sm');
					margin-bottom: theme('spacing.1');
					color: theme('colors.gray.600');
				}
			}
		}
		&-label-inside {
			input {
				padding: 0 theme('spacing.4');
				padding-top: theme('spacing.3');
				line-height: theme('spacing.12');
				font-size: theme('fontSize.md');
				border-radius: theme('borderRadius.DEFAULT');
				border: 1px solid theme('colors.gray.300');
			}

			#{$block} {
				&__label {
					position: absolute;
					font-weight: 700;
					font-size: theme('fontSize.xs');
					font-family: theme('fontFamily.title');
					color: theme('colors.primary.500');
					text-transform: uppercase;
					z-index: 2;
					left: theme('spacing.4');
					top: theme('spacing.2');
					pointer-events: none;
				}

				&__icon {
					position: absolute;
					z-index: 2;
					top: 50%;
					transform: translateY(-50%);
					right: theme('spacing.4');
					display: flex;
					color: theme('colors.gray.300');
					pointer-events: none;
					button {
						display: flex;
						pointer-events: auto;
						color: theme('colors.gray.600');
					}

					svg {
						width: theme('spacing.7');
						height: theme('spacing.7');
					}
				}
			}
		}
	}

	&-group {
		&--horizontal {
			.input {
				input {
					border-radius: 0;
					border-left-width: 0;
					border-right-width: 0;
				}
				&:first-child,
				&.first-in-group {
					input {
						border-left-width: 2px;
						border-top-left-radius: theme('borderRadius.DEFAULT');
						border-bottom-left-radius: theme('borderRadius.DEFAULT');
					}
				}
				&:last-child,
				&.last-in-group {
					input {
						border-right-width: 2px;
						border-top-right-radius: theme('borderRadius.DEFAULT');
						border-bottom-right-radius: theme('borderRadius.DEFAULT');
					}
				}
			}
		}
	}
	/* clears the 'X' from Internet Explorer */
	[type='search']::-ms-clear,
	[type='search']::-ms-reveal {
		display: none;
		width: 0;
		height: 0;
	}

	/* clears the 'X' from Chrome */
	[type='search']::-webkit-search-decoration,
	[type='search']::-webkit-search-cancel-button,
	[type='search']::-webkit-search-results-button,
	[type='search']::-webkit-search-results-decoration {
		display: none;
	}
}

.input textarea {
	width: 100%;
	height: 100%;
	resize: none;
	font-size: theme('fontSize.base');
	padding: theme('spacing.2') theme('spacing.3');
	border-radius: theme('borderRadius.DEFAULT');
	border: 1px solid theme('colors.gray.300');
	line-height: theme('lineHeight.6');
	min-height: calc(2 * theme('spacing.2') + 5 * theme('lineHeight.6'));
}
