.react-tel-input {
	.selected-flag {
		transition: background-color 0.2s ease;
		width: 100% !important;
		padding-left: 20px !important;
		background-color: $gray50 !important;
		border-radius: 3px 0 0 3px;
	}

	.flag-dropdown .country-list {
		@media (min-width: 400px) {
			width: 360px;
		}
		width: calc(100vw - 40px);
		box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05),
			0px 20px 20px rgba(0, 0, 0, 0.1);
		border-radius: 10px;

		li {
			padding: 10px 20px;
			display: flex;
			align-items: center;

			&.search {
				z-index: 100;
				display: flex;
				align-items: center;
			}

			&.divider {
				display: none;
			}
		}
		.search-emoji {
			font-size: 0;
			background: #ededed;
			height: 40px;
			border-top-left-radius: 6px;
			border-bottom-left-radius: 6px;
			display: flex;
			align-items: center;
			padding-left: 10px;
			padding-right: 5px;

			&::before {
				content: '';
				display: block;
				background-image: url('/assets/ui-icons/search-icon.svg');
				background-repeat: no-repeat;
				background-position: center;
				filter: brightness(0);
				width: 24px;
				height: 24px;
			}
		}

		.search-box {
			border-top-right-radius: 6px;
			border-bottom-right-radius: 6px;
			background: #ededed;
			width: calc(100% - 39px);
			border: none;
			padding: 0;
			height: 40px;
			font-size: 18px;
			margin: 0;
			font-weight: bold;
			text-transform: capitalize;

			&::placeholder {
				font-weight: 400;
			}
		}

		.flag {
			transition: background-color 0.2s ease;
			border-radius: 2px;
			position: static;
			margin-right: 13px;
			transform: translateY(-3px);
		}

		.country {
			font-size: 16px;
			&:hover {
				background: #ededed;
				span {
					color: #254da1;
				}
			}
		}
	}

	.arrow {
		background-image: url('/assets/ui-icons/arrow-icon.svg');
		left: 32px !important;
		border: none !important;
		background-size: cover;
		width: 14px !important;
		height: 8px !important;
		top: 43% !important;
	}
}
