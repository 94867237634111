#litto .swiper {
	&-slide {
		border-radius: 8px;
		display: flex;
		// overflow: hidden !important;
		// width: auto !important;
	}

	&-pagination-fraction {
		position: absolute;
		bottom: 16px;
		left: 16px;
		z-index: 20;
		background: $brand;
		padding: 4px 8px;
		border-radius: 6px;
		width: auto;
	}

	&-pagination-bullets {
		display: flex;
		justify-content: center;
		bottom: -30px !important;
		max-width: 70%;
		width: 520px !important;
		left: 50% !important;
		transform: translateX(-50%) !important;
		border-radius: 9999px;
		background: #ccc;
		height: 4px;
	}

	&-pagination-bullet {
		margin: 0 !important;
		display: flex;
		align-items: center;
		flex-grow: 1;
		height: 40px;
		border-radius: 0;
		background: transparent;
		transform: translateY(calc(-50% + 2px)) !important;

		&:only-child {
			display: none;
		}

		&:after {
			content: '';
			width: 100%;
			height: 4px;
		}
	}

	&-pagination-bullet-active:after {
		border-radius: 9999px;
		background: #3f3f3f;
	}
}

.overflow-visible {
	.swiper {
		&-horizontal {
			overflow: visible !important;

			* {
				overflow: visible !important;
			}
		}

		&-slide {
			overflow: hidden !important;
		}
	}
}

#litto .promo-banner .swiper {
	&-horizontal {
		padding-bottom: 90px;
	}

	&-pagination {
		bottom: 50px !important;
		background: white;

		&-bullet {
			transition: opacity 0.2s ease;
			margin: 0;
			align-items: center;
			width: 60px;
			height: 40px;
			border-radius: 0;
			background: transparent;
			opacity: 1;

			&:not(.swiper-pagination-bullet-active):hover {
				opacity: 0.3;
			}

			&.swiper-pagination-bullet-active:after {
				background: $primary500;
			}
		}
	}
}

#litto .swiper {
	&-button-prev,
	&-button-next {
		transition: background 0.2s ease, opacity 0.2s ease;
		display: flex;
		height: 50px;
		width: 50px;
		border-radius: 9999px;
		background-color: $primary500;
		position: absolute;
		z-index: 10;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		border: solid 2px white;

		&::before {
			content: '';
			width: 50%;
			height: 4px;
			background-color: white;
			position: absolute;
			display: block;
			top: 50%;
			left: 45%;
			transform: translateX(-40%) translateY(-50%);
			border-radius: 20px;
		}

		&:after {
			content: '';
			width: 25%;
			height: 25%;
			position: absolute;
			display: block;
			top: 50%;
			left: 35%;
			border-top: 4px solid white;
			border-left: 4px solid white;
			transform: rotate(-45deg) translateX(0%) translateY(-66%);
			border-radius: 2px;
		}

		&:hover {
			background-color: $primary700;
		}
	}

	&-button-prev {
		left: -25px;

		@media (max-width: 767px) {
			left: -5px;
		}
	}

	&-button-next {
		right: -25px;
		transform: translateY(-50%) rotate(180deg);

		@media (max-width: 767px) {
			right: -5px;
		}
	}

	&-button-disabled {
		opacity: 0;
		pointer-events: none;
	}
}

body .overflow-visible-arrows-only {
	overflow: visible;
	position: relative;

	&::before {
		transform: translateX(-100%);
	}

	&::after {
		transform: translateX(100%);
		background: white;
	}

	&::before,
	&::after {
		content: '';
		width: 100%;
		position: absolute;
		z-index: 2;
		background: white;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		@media (max-width: 1023px) {
			display: none;
		}
	}
}

// swiper.css
:root {
	--swiper-theme-color: #007aff;
}
.swiper {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	padding: 0;
	/* Fix of Webkit flickering */
	z-index: 1;
}
.swiper-vertical > .swiper-wrapper {
	flex-direction: column;
}
.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	transition-property: transform;
	box-sizing: content-box;
}
.swiper-android .swiper-slide,
.swiper-wrapper {
	transform: translate3d(0px, 0, 0);
}
.swiper-pointer-events {
	touch-action: pan-y;
	&.swiper-vertical {
		touch-action: pan-x;
	}
}
.swiper-slide {
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
	transition-property: transform;
}
.swiper-slide-invisible-blank {
	visibility: hidden;
}
/* Auto Height */
.swiper-autoheight {
	&,
	.swiper-slide {
		height: auto;
	}

	.swiper-wrapper {
		align-items: flex-start;
		transition-property: transform, height;
	}
}

/* 3D Effects */
.swiper-3d {
	&,
	&.swiper-css-mode .swiper-wrapper {
		perspective: 1200px;
	}
	.swiper-wrapper,
	.swiper-slide,
	.swiper-slide-shadow,
	.swiper-slide-shadow-left,
	.swiper-slide-shadow-right,
	.swiper-slide-shadow-top,
	.swiper-slide-shadow-bottom,
	.swiper-cube-shadow {
		transform-style: preserve-3d;
	}
	.swiper-slide-shadow,
	.swiper-slide-shadow-left,
	.swiper-slide-shadow-right,
	.swiper-slide-shadow-top,
	.swiper-slide-shadow-bottom {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 10;
	}
	.swiper-slide-shadow {
		background: rgba(0, 0, 0, 0.15);
	}
	.swiper-slide-shadow-left {
		background-image: linear-gradient(
			to left,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0)
		);
	}
	.swiper-slide-shadow-right {
		background-image: linear-gradient(
			to right,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0)
		);
	}
	.swiper-slide-shadow-top {
		background-image: linear-gradient(
			to top,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0)
		);
	}
	.swiper-slide-shadow-bottom {
		background-image: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.5),
			rgba(0, 0, 0, 0)
		);
	}
}

/* CSS Mode */
.swiper-css-mode {
	> .swiper-wrapper {
		overflow: auto;
		scrollbar-width: none; /* For Firefox */
		-ms-overflow-style: none; /* For Internet Explorer and Edge */
		&::-webkit-scrollbar {
			display: none;
		}
	}
	> .swiper-wrapper > .swiper-slide {
		scroll-snap-align: start start;
	}
}
.swiper-horizontal.swiper-css-mode {
	> .swiper-wrapper {
		scroll-snap-type: x mandatory;
	}
}
.swiper-vertical.swiper-css-mode {
	> .swiper-wrapper {
		scroll-snap-type: y mandatory;
	}
}
.swiper-centered {
	> .swiper-wrapper::before {
		content: '';
		flex-shrink: 0;
		order: 9999;
	}
	&.swiper-horizontal {
		> .swiper-wrapper > .swiper-slide:first-child {
			margin-inline-start: var(--swiper-centered-offset-before);
		}
		> .swiper-wrapper::before {
			height: 100%;
			width: var(--swiper-centered-offset-after);
		}
	}
	&.swiper-vertical {
		> .swiper-wrapper > .swiper-slide:first-child {
			margin-block-start: var(--swiper-centered-offset-before);
		}
		> .swiper-wrapper::before {
			width: 100%;
			height: var(--swiper-centered-offset-after);
		}
	}

	> .swiper-wrapper > .swiper-slide {
		scroll-snap-align: center center;
	}
}
