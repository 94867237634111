@mixin setFlex {
	display: flex;
	margin-left: calc(-1 * theme("spacing.5"));
	margin-right: calc(-1 * theme("spacing.5"));
	> * {
		width: 50%;
		padding-left: theme("spacing.5");
		padding-right: theme("spacing.5");
	}
}

@mixin unsetFlex {
	display: block;
	margin-left: 0;
	margin-right: 0;
	> * {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}
}

.static-page {
    h1 {
        font-size: theme("fontSize.4xl");
        margin-top: 20px;
        margin-bottom: 20px;
    }
    h2 {
        font-size: theme("fontSize.3xl");
        margin-bottom: 10px;
        margin-top: 10px;
    }
    h2 {
        font-size: theme("fontSize.2xl");
        margin-bottom: 10px;
        margin-top: 10px;
    }
    h3 {
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: theme("fontSize.xl");
    }
    > *:not(:last-child):not(h1):not(h2):not(h3) {
        margin-bottom: theme("spacing.8");
    }
    .html-content {
        ul {
            list-style: disc;
        }

        ol {
            list-style-type: decimal;
        }

        li {
            margin-left: 2em;
        }
    }
    .list-block {
        > * {
            @media (min-width: theme("screens.sm")) {
                @include setFlex;
            }
            @media (min-width: theme("screens.md")) {
                @include unsetFlex;
            }
            @media (min-width: theme("screens.lg")) {
                @include setFlex;
            }
        }
    }

    .accordion {
        > p > span {
            @media (min-width: theme("screens.lg")) {
                font-size: theme("fontSize.md");
            }
        }

        .html-content * {
            font-size: 16px;
        }
    }
}