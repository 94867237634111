#litto {
	.col-6\/12 {
		width: calc(2 * var(--m-col) + 1 * var(--m-gutter));
	}

	.col-10\/12 {
		width: calc(10 * var(--col) + 9 * var(--gutter));
	}

	.col-1\/12-g {
		width: calc(var(--col) + var(--gutter));
	}

	@media (min-width: 550px) {
		.sm\:col-6\/12 {
			width: calc(6 * var(--col) + 5 * var(--gutter));
		}
	}

	@media (min-width: 768px) {
		.md\:col-2\/12 {
			width: calc(2 * var(--r-col) + 1 * var(--gutter));
		}

		.md\:col-3\/12 {
			width: calc(3 * var(--r-col) + 2 * var(--gutter));
		}

		.md\:col-4\/12 {
			width: calc(4 * var(--r-col) + 3 * var(--gutter));
		}

		.md\:col-5\/12 {
			width: calc(5 * var(--r-col) + 4 * var(--gutter));
		}

		.md\:col-6\/12 {
			width: calc(6 * var(--r-col) + 5 * var(--gutter));
		}

		.md\:col-8\/12 {
			width: calc(8 * var(--r-col) + 7 * var(--gutter));
		}
	}

	@media (min-width: $siteWidth) {
		.md\:col-3\/12 {
			width: calc(3 * var(--col) + 2 * var(--gutter));
		}

		.lg\:col-2\/12 {
			width: calc(2 * var(--col) + 1 * var(--gutter));

			@media (max-width: $site-width) {
				width: calc(2 * var(--r-col) + 1 * var(--gutter));
			}
		}

		.lg\:col-4\/12 {
			width: calc(4 * var(--col) + 3 * var(--gutter));

			@media (max-width: $site-width) {
				width: calc(4 * var(--r-col) + 3 * var(--gutter));
			}
		}

		.lg\:col-5\/12 {
			width: calc(5 * var(--col) + 4 * var(--gutter));

			@media (max-width: $site-width) {
				width: calc(5 * var(--r-col) + 4 * var(--gutter));
			}
		}

		.lg\:col-6\/12 {
			width: calc(6 * var(--col) + 5 * var(--gutter));

			@media (max-width: $site-width) {
				width: calc(6 * var(--r-col) + 5 * var(--gutter));
			}
		}

		.lg\:col-8\/12 {
			width: calc(8 * var(--col) + 7 * var(--gutter));

			@media (max-width: $site-width) {
				width: calc(8 * var(--r-col) + 7 * var(--gutter));
			}
		}
	}

	// .mx-col-r {
	//     margin-left: calc(1 * var(--col) + 1 * var(--gutter));
	//     margin-right: calc(1 * var(--col) + 1 * var(--gutter));
	// }
}
